import { render, staticRenderFns } from "./Question61.vue?vue&type=template&id=b7fdf458&"
import script from "./Question61.vue?vue&type=script&lang=js&"
export * from "./Question61.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
