<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Rank each of the following molecules in terms of the expected <b>boiling point</b>.
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="molecules"
        class="mb-1 pl-8"
        style="max-width: 400px"
        prepend-text="Highest"
        append-text="Lowest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';

const MOLECULE_SETS = [
  ['$\\ce{CH3Cl}$', '$\\ce{CH3OH}$', '$\\ce{CH4}$'],
  ['$\\text{n-pentane}$', '$\\text{neopentane}$', '$\\text{isopentane}$'],
  ['$\\text{propane}$', '$\\text{butane}$', '$\\text{hexane}$'],
];

export default {
  name: 'Question61',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    moleculeSet() {
      return this.taskState.getValueBySymbol('moleculeSet').content.value;
    },
    molecules() {
      return MOLECULE_SETS[this.moleculeSet - 1];
    },
  },
};
</script>
